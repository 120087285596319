<template>
  <div>
    <v-overlay
      :value="showOverlay">
      <v-card>
        <v-card-text>
          {{ migrateStatus }}
        </v-card-text>
      </v-card>
    </v-overlay>
    <v-container fluid>
      <v-row>
        <v-col cols="4" class="tree-view">
          <tree-view ref="treeView"
            :show-checkbox="false"
            :curriculum-guid="curriculumGuid"
            :webcon-user="webConUser"
            @selected="selectNode">
          </tree-view>
        </v-col>
        <v-col cols="8" class="tree-view">
          <v-row>
            <v-col>
              <v-card v-if="showNodeDetail" elevation="1">
                <v-card-title>Object Detail</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      Object Type:
                    </v-col>
                    <v-col>
                      {{selectedNode.type}}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      Object Name:
                    </v-col>
                    <v-col>
                      {{selectedNode.name}}
                    </v-col>
                  </v-row>
                  <v-row v-if="showEdit">
                    <v-col>
                      <v-btn color="primary lighten-2" @click="editClick">Edit</v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-if="showPlaylist">
                    <v-col>
                      <v-btn color="success lighten-1" @click="showPlaylistDialog">Add Playlist</v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <correlations
                v-if="showCorrelations"
                :startCollapsed="true"
                :selected-node="selectedNode"
                :curriculum-guid="curriculumGuid"
                :webcon-user="webConUser">
              </correlations>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <custom-data-fields
                v-if="showCustomDataFields"
                :startCollapsed="true"
                :selected-node="selectedNode"
                :curriculum-guid="curriculumGuid"
                :hierarchy-guid="selectedNode.guid"
                :webcon-user="webConUser">
              </custom-data-fields>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <hierarchy-objects
                v-if="showHierarchyObjects"
                :startCollapsed="true"
                :selected-node="selectedNode"
                :curriculum-guid="curriculumGuid"
                :webcon-user="webConUser">
              </hierarchy-objects>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="playlistDialog.show"
      persistent
      width="50%">
      <v-card>
        <v-card-title>
          New Playlist Details
        </v-card-title>
        <v-card-text>
          <v-progress-linear
            indeterminate
            rounded
            height="15"
            color="primary"
            v-if="playlistDialog.loading">
          </v-progress-linear>
          <v-container v-else>
            <v-row>
              <v-col>
                <v-text-field label="Playlist Name" v-model="playlistDialog.name"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select 
                  label="Language"
                  v-model="playlistDialog.language"
                  :items="playlistDialog.languages">
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="addPlaylist" color="primary">Save</v-btn>
          <v-btn text @click="playlistDialog.show = false" color="red">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TreeView from '../components/TreeView';
import CustomDataFields from '../components/CustomDataFields';
import HierarchyObjects from '../components/HierarchyObjects';
import Correlations from '../components/Correlations.vue';
import publishService from '../services/publish.service';

export default {
  components: {
    TreeView,
    CustomDataFields,
    HierarchyObjects,
    Correlations,
  },
  data() {
    return {
      selectedNode: null,
      // curriculumGuid: '6A993627-C8A8-4E79-91F1-6A8D71C8EF59',
      webConUser: '',
      showOverlay: true,
      migrateStatus: 'Checking Implement Status',
      playlistDialog: {
        show: false,
        loading: false,
        name: '',
        language: '',
        languages: [
          { text: 'English', value: 'en' },
          { text: 'Spanish', value: 'es' }
        ],
      }
    }
  },
  computed: {
    showNodeDetail() {
      if (!this.selectedNode) {
        return false;
      }
      return true;
    },
    showCustomDataFields() {
      if (!this.selectedNode) {
        return false;
      }
      return true;
      // return this.selectedNode.type.toUpperCase() === 'FOLDER';
    },
    showHierarchyObjects() {
      if (!this.selectedNode) {
        return false;
      }
      return this.selectedNode.type.toUpperCase() === 'FOLDER';
    },
    showCorrelations() {
      if (!this.selectedNode) {
        return false;
      }
      return true;
    },
    hideDetail() {
      return !this.selectedNode;
    },
    curriculumGuid() {
      return this.showOverlay ? '' : this.$route.params.curriculumGuid;
    },
    showEdit() {
      return this.selectedNode.type.toLowerCase() !== 'folder' && this.selectedNode.instanceId !== 0;
    },
    showPlaylist() {
      // Button Removed for this release due to issues.
      return false && this.selectedNode.type.toLowerCase() === 'folder';
    }
  },
  methods: {
    async fetchData() {
      this.checkJobStatus();
      // this.showOverlay = !(await publishService.migrateComplete(this.$route.params.curriculumGuid));
      // if (this.showOverlay) {
      //   this.migrateStatus = 'There is an Implement/Import job pending for this Curriculum.  Please try again later';
      // }
    },
    checkJobStatus() {
      this.migrateStatus = 'Checking Implement Status';
      publishService.migrateComplete(this.$route.params.curriculumGuid)
        .then(result => {
          if (result) {
            this.showOverlay = false;
          } else {
            this.showOverlay = true;
            this.migrateStatus = 'There is an Implement job pending for this Curriclum. Checking again in 30 seconds.';
            window.setTimeout(this.checkJobStatus, 30000);
          }
        });
    },
    receiveDataFromParent(event) {
      if (event.origin !== process.env.VUE_APP_PARENT_IFRAME) {
        // console.log(`Expected Origin mismatch. Received ${event.origin}`);
        return;
      }
      console.debug('Recieved Message from Parent - Event: %O', event);

      const { data } = event;
      const { configuration } = data;
      const { sdkConfiguration } = configuration;

      if (data.message !== 'SET_CONFIGURATION') {
        console.error(`Expected Message mismatch. Received ${event.message}`);
        return;
      }
      if (!sdkConfiguration) {
        console.error('Received invalid SdkCnfiguratin Object. Check Webcon configurations');
        return;
      }

      if (sdkConfiguration.webconUser) {
        this.webConUser = sdkConfiguration.webconUser
      } else {
        console.error('Missing WebCon User form sdkConfiguration Object.  Chekc Webcon configurations');
      }
    },
    selectNode(item) {
      this.selectedNode = null;
      if (!item || !item.type) {
        this.selectedNode = null;
        return;
      }

      this.selectedNode = item;
    },
    editClick() {
      window.location = `${process.env.VUE_APP_PARENT_IFRAME}/embed/form/db/1/element/${this.selectedNode.instanceId}/form`;
      // window.parent.postMessage({
      //   message: 'OPEN_LINK',
      //   url: `${process.env.VUE_APP_PARENT_IFRAME}/embed/form/db/1/element/${this.selectedNode.instanceId}/form`,
      // }, '*');
      // parent.window.open(`${process.env.VUE_APP_PARENT_IFRAME}/db/1/app/10/element/${this.selectedNode.instanceId}/form`);
    },
    addPlaylist() {
      this.playlistDialog.loading = true;
      this.$store.dispatch('createPlaylist', {
        playlistName: this.playlistDialog.name,
        hierarchyGuid: this.selectedNode.guid,
        language: this.playlistDialog.language,
        webconUser: this.webConUser,
      }).then(() => {
        this.$store.dispatch('getCurriculumHierarchy',
          this.curriculumGuid,
          this.webConUser);
          this.playlistDialog.show = false;
      });
    },
    showPlaylistDialog() {
      this.playlistDialog.language = '';
      this.playlistDialog.name = '';
      this.playlistDialog.loading = false;
      this.playlistDialog.show = true;
    }
  },
  mounted() {
    window.addEventListener('message', this.receiveDataFromParent, false);

    window.parent.postMessage({ message: 'IFRAME_LOADED' }, '*');

    if (process.env.VUE_APP_MODE === 'Development'){
      this.webConUser = 'rrastelli@gedu-demo-pcgus.com';
    }

    this.fetchData();
  }
}
</script>

<style scoped>
.col.tree-view {
  height: 90vh;
  min-height: 90vh;
  overflow-y: scroll;
}
</style>