export default {
  data: () => {
    return {
    };
  },
  computed: {
    fileTypes() {
      return this.$store.getters.PublishTypeMap;
    }
  },
  mounted() {
    this.$store.dispatch('getPublishTypeMap');
  },
  methods: {
    parseFileType(type) {
      if (!type) {
        return '';
      }
      switch (type.toLowerCase()) {
        case 'googledoc':
          return 'googleDoc';
        case 'googleslide':
          return 'googleSlides';
        case 'googlesheet':
          return 'googleSheets';
        case 'msworddocument':
          return 'wordDoc';
        case 'mspowerpoint':
          return 'mspowerpoint';
        case 'msexcel':
          return 'msexcel';
        case 'pdf':
          return 'pdf';
        case 'html':
          return 'template';
        case 'unpublishable':
          return 'unpublishable';
        default:
          return 'unknown';
      }
    },
    publishTypes(sourceMimeType) {
      console.log('fileTypeParser.mixin:publishTypes - %s', sourceMimeType);
      const typeObj = this.fileTypes.filter((item) => {
        return item.sourceType.toUpperCase() === sourceMimeType.toUpperCase();
      })[0];
      return typeObj ? typeObj.publishTypes : [];
    },
    publishNameFromType(sourceMimeType, publishMimeType) {
      const publishTypes = this.publishTypes(sourceMimeType);
      const publishType = publishTypes.filter((type) => type.sourceType === publishMimeType);
      if (publishType && publishType.length === 1) {
        return publishType[0].name;
      }
      return publishMimeType;
    }
  },
}