<template>
  <div>
    <v-card elevation="1">
      <v-card-title>
        <v-row>
          <v-col cols="6">
            <span @click="toggleView"><v-icon>{{toggleIcon}}</v-icon></span>
            <span @click="toggleView">Standards Alignments</span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col v-if="!isCollapsed">
            <v-btn text @click="showAddDialog()"><v-icon color="green">mdi-plus-circle-outline</v-icon>Add New</v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text v-if="!isCollapsed">
        <v-progress-linear
          v-if="loading"
          indeterminate>
        </v-progress-linear>
        <v-row>
          <v-col>
            <v-select
              v-if="!loading"
              v-model="selectedStandard"
              :items="standardSets"
              item-text="displayName"
              return-object
              placeholder="Standard Set">
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :items="filteredStandards"
              :headers="headers"
              :loading="loading"
              v-model="appliedStandards"
              item-key="identifier"
              dense
              disable-pagination
              disable-sort
              show-select
              @item-selected="saveSelection"
              hide-default-footer>
              <template v-slot:loading>
                Fetching Standards Data...
              </template>
              <template v-slot:progress>
                <v-progress-linear
                  v-if="loading"
                  indeterminate
                  rounded>
                </v-progress-linear>
              </template>
              <template v-slot:item.id="{ item }">
                <v-btn text color="green lighten-2"
                  title="Roll Standard down to children"
                  @click="rollDown(item)">
                  <v-icon>mdi-arrow-bottom-right-bold-box</v-icon>
                </v-btn>
              </template>
              <template v-slot:header.data-table-select>

              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row v-if="isFolder">
          <v-col>
            <v-btn text color="green lighten-1" @click="rollDownHierarchy">
              <v-icon>mdi-arrow-bottom-right-bold-box</v-icon>Roll Down to Sub-Objects
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="showDialog"
      persistent
      width="60%">
      <v-card rounded shaped>
        <v-card-title>
          Add Standard Correlations
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                placeholder="Search String"
                v-model="addDialog.searchString">
              </v-text-field>
            </v-col>
            <v-col>
              <v-select
                v-if="!addDialog.loading"
                v-model="addDialog.selectedStandard"
                :items="addDialog.standardSets"
                item-text="displayName"
                return-object
                placeholder="Standard Set">
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="scrolling-content">
              <v-data-table
                :items="filteredDialogStandards"
                :headers="addDialog.headers"
                :loading="addDialog.loading"
                v-model="addDialog.selectedItems"
                fixed-header
                dense
                disable-sort
                show-select
                :items-per-page="10"
                :footer-props="{
                  'items-per-page-options': [10,20,30,50,100],
                  'items-per-page-text': 'Standards per page'
                }"
                class="">
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="saveDialog">
            Save
          </v-btn>
          <v-btn text color="red lighten-2" @click="hideDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dlg ref="confirm"></confirm-dlg>
  </div>
</template>

<script>
import collapsible from '../mixins/collapsible.mixin';
import hierarchyService from '../services/hierarchy.service';
import confirmDlg from './ConfirmDialog.vue';

export default {
  mixins: [collapsible],
  components: {
    confirmDlg
  },
  props: {
    selectedNode: {
      type: Object,
    },
    curriculumGuid: {
      type: String,
    },
    hierarchyGuid: {
      type: String,
    },
    webconUser: {
      type: String,
    },
  },
  data() {
    return {
      showDialog: false,
      addDialog: {
        loading: true,
        searchString: '',
        standardSets: [],
        selectedStandard: {},
        standards: [],
        selectedItems: [],
        headers: [
          { text: 'Standard Set', align: 'start', sortable: false, value: 'standardSetDisplay' },
          { text: 'Standard Code', align: 'start', sortable: false, value: 'humanCodingScheme' },
          { text: 'Description', align: 'start', sortable: false, value: 'fullStatement' },
        ],
      },
      showCardBody: true,
      loading: false,
      standardSets: [],
      selectedStandard: {},
      standards: [
        {
          id: 1,
          standardSetName: 'CPS:IELAS(2010)',
          standardCode: 'CPS ELA.1.3.1',
          description: 'Standard description',
        }
      ],
      appliedStandards: [],
      headers: [
        // { text: '', align: 'center', sortable: false, value: 'id' },
        { text: 'Standard Set', align: 'start', sortable: false, value: 'standardSetDisplay' },
        { text: 'Standard Code', align: 'start', sortable: false, value: 'code' },
        { text: 'Description', align: 'start', sortable: false, value: 'name' },
      ],
    }
  },
  computed: {
    filteredStandards() {
      if (this.selectedStandard.displayName === 'All') {
        return this.standards;
      } else {
        return this.standards.filter((item) => {
          return item.standardSetDisplay === this.selectedStandard.displayName;
        });
      }
    },
    filteredDialogStandards() {
      let tempList;
      if (this.addDialog.selectedStandard.displayName === 'All') {
        tempList = this.addDialog.standards;
      } else {
        tempList = this.addDialog.standards.filter((item) => {
          return item.standardSetDisplay === this.addDialog.selectedStandard.displayName;
        });
      }
      if (!this.addDialog.searchString || this.addDialog.searchString.length === 0) {
        return tempList;
      } else {
        return tempList.filter((item) => {
          return item.fullStatement.indexOf(this.addDialog.searchString) >= 0 ||
            (item.humanCodingScheme && item.humanCodingScheme.indexOf(this.addDialog.searchString) >= 0);
        })
      }
    },
    isFolder() {
      return this.$props.selectedNode.type.toLowerCase() === 'folder';
    }
  },
  methods: {
    standardSetDisplayName(docIdentifier) {
      const standard = this.standardSets.filter((set) => {
        return set.docIdentifier === docIdentifier;
      })[0];
      return standard.name ? standard.name.match(/[A-Z:(0-9)]/g).join('') : standard.title.match(/[A-Z:(0-9)]/g).join('');
    },
    async saveSelection(selected) {
      console.log('Checkbox Clicked', selected);
      if (selected.value) {
        // Save Standard
        const newObj = await hierarchyService.createStandard({
            reference: this.selectedNode.type === 'Folder' ? this.selectedNode.guid : this.selectedNode.objectGuid,
            refHierarchyPointer: this.selectedNode.type === 'Folder' ? 'Folder' : 'Object',
            correlationType: 'exact',
            caseIdentifier: selected.item.identifier,
            caseDocIdentifier: selected.item.docIdentifier,
            curriculumGuid: this.$store.getters.CurriculumGuid,
          },
          this.$props.webconUser);
        this.appliedStandards.push({
          id: newObj.id,
          identifier: newObj.caseIdentifier,

        })  
      } else {
        // Remove Standard
        const selectedRefModel = this.appliedStandards.filter(std => {
            return std.identifier === selected.item.identifier;
          })[0];
        if (await this.$refs.confirm.showDialog(
          'Confirm Standard Removal',
          'This will remove all applied standards from any child folders or objects.  Are you sure you want to continue this action?',
          {
            width: '50%',
            confirmButtonTxt: "Yes, I'm sure",
            cancelButtonTxt: "No, I've chagned my mind",
          })) {
          if (selectedRefModel) {
            console.log('Removing Standard Id %o', selectedRefModel.id);
            await hierarchyService.removeStandard(selectedRefModel.id, this.$props.webconUser);
            // this.appliedStandards.splice(this.appliedStandards.indexOf(selectedRefModel), 1);
          } else {
            console.log('Cannot find reference item');
          }
        } else {
          this.appliedStandards.push(selectedRefModel);
        }
      }
    },
    async fetchData() {
      this.loading = true;
      // Get Standard Sets for Drop Down
      const defaultAll = {
        displayName: "All",
        id: -1,
      };
      const setResults = await hierarchyService.getCurriculumStandardSets(this.$props.curriculumGuid);
      this.standardSets = [];
      this.standardSets = setResults.items;
      this.standardSets.forEach(standard => {
        standard.displayName = this.standardSetDisplayName(standard.docIdentifier);
      });
      this.standardSets.unshift(defaultAll);
      this.selectedStandard = defaultAll;

      let availableMethod;
      let appliedMethod;
      let relevantGuid;

      if (this.$props.selectedNode.type === 'Folder') {
        availableMethod = hierarchyService.getHierarchyAvailableStandardSets;
        appliedMethod = hierarchyService.getHierarchyAppliedStandardSets;
        relevantGuid = this.$props.selectedNode.guid;
      } else {
        availableMethod = hierarchyService.getObjectAvailableStandardSets;
        appliedMethod = hierarchyService.getObjectAppliedStandardSets;
        relevantGuid = this.$props.selectedNode.objectGuid;
      }

      // Get Available Standards
      const availableResults = await availableMethod(relevantGuid);
      this.standards = availableResults.items;
      this.standards.forEach(standard => {
        standard.id = standard.identifier;
        standard.standardSetDisplay = standard.standardSetName.match(/[A-Z:(0-9)]/g).join('');
      });

      // Get Applied Standards
      const appliedResults = await appliedMethod(relevantGuid);
      this.appliedStandards = appliedResults.items;

      this.loading = false;
    },
    async fetchDialogData() {
      this.addDialog.loading = true;
      // Get Standard Sets for Drop Down
      const defaultAll = {
        displayName: "All",
        id: -1,
      };
      const standardsResults = await hierarchyService.getCurriculumStandardSets(this.$props.curriculumGuid);
      this.addDialog.standardSets = [];
      this.addDialog.standardSets = standardsResults.items;
      this.addDialog.standardSets.forEach(standard => {
        standard.displayName = this.standardSetDisplayName(standard.docIdentifier);
      });
      this.addDialog.standardSets.unshift(defaultAll);
      this.addDialog.selectedStandard = defaultAll;

      // Get Standards for list
      // Filter by available Sets
      const promise = new Promise((resolve) => {
        const docIds = [];
        this.addDialog.standardSets.forEach((standardSet) => {
          if (standardSet.id === -1) return;
          docIds.push(standardSet.docIdentifier);
        });
        const existingIds = [];
        this.standards.forEach((standard) => {
          existingIds.push(standard.identifier);
        });
        console.debug(docIds);
        this.addDialog.standards = [];
        this.$store.getters.StandardDetails.forEach(standard => {
          if (docIds.includes(standard.docIdentifier) && !existingIds.includes(standard.identifier)) {
            standard.standardSetDisplay = this.standardSetDisplayName(standard.docIdentifier)
            this.addDialog.standards.push(standard);
          }
        });
        resolve();
      });

      promise.then(() => {
        this.addDialog.loading = false;
      });
    },
    async showAddDialog() {
      this.showDialog = true;
      this.fetchDialogData();
    },
    async saveDialog() {
      const requestPromises = [];
      this.addDialog.selectedItems.forEach(selected => {
        requestPromises.push(hierarchyService.createStandard({
            reference: this.selectedNode.type === 'Folder' ? this.selectedNode.guid : this.selectedNode.objectGuid,
            refHierarchyPointer: this.selectedNode.type === 'Folder' ? 'Folder' : 'Object',
            correlationType: 'exact',
            caseIdentifier: selected.identifier,
            caseDocIdentifier: selected.docIdentifier,
            curriculumGuid: this.$store.getters.CurriculumGuid,
          },
          this.webconUser,
        ))
      });

      await Promise.all(requestPromises);

      await this.fetchData();

      //TODO: Save Data
      this.hideDialog();
    },
    hideDialog() {
      this.addDialog.selectedItems = [];
      this.addDialog.searchString = '';
      this.addDialog.selectedStandard = {};
      
      this.showDialog = false;
    },
    async rollDownHierarchy() {
      await hierarchyService.rollDownStandards(this.$props.selectedNode.guid, this.$props.webconUser);
    }
  },
  mounted() {
    this.fetchData();
    this.$store.dispatch('getStandards');
    this.$store.dispatch('getStandardDetails');
  },
  watch: {
    curriculumGuid() {
      this.fetchData();
    },
    selectedNode() {
      this.fetchData();
    }
  },
}
</script>

<style lang="scss" scoped>
.height-limit-table {
  max-height: 300px;
  overflow-y: scroll;
}

.scrolling-content {
  max-height: 400px;
  overflow-y: scroll;
}
</style>