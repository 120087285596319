import axios from 'axios';

const rootUrl = `${process.env.VUE_APP_AUTHORING_URL}`;
const createUrl = `${rootUrl}/object/from-hierarchy`;

const author_key = `${process.env.VUE_APP_AUTHORING_ID}`;
const author_secret = `${process.env.VUE_APP_AUTHORING_SECRET}`;

let token = '';
let tokenExpire = '';

async function doAuth() {
  if (token && tokenExpire > new Date()) {
    return token;
  }
  const url = `${rootUrl}/connect/token`;
  const response = await axios(
    {
      url: url,
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      data: `grant_type=client_credentials&scope=api&client_id=${author_key}&client_secret=${author_secret}`,
    }
  );
  token = response.data.access_token;
  const dt = new Date();
  tokenExpire = new Date(dt.setMinutes(dt.getMinutes() + (response.data.expires_in / 60) - 5));
}
export default {
  async createPlaylist(payload, webconUser) {
    await doAuth();

    const url = `${createUrl}`;
    const response = await axios({
      url: url,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
        'webcon-user': webconUser,
      },
      data: JSON.stringify(payload),
    });

    return response.data;
  }
}