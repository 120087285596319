import hierarchyService from '../services/hierarchy.service';

export default {
  state: {
    standards: [],
    standardDetails: [],
  },
  getters: {
    Standards: (state) => state.standards,
    StandardDetails: (state) => state.standardDetails,
  },
  mutations: {
    SET_STANDARDS(state, payload) {
      state.standards = payload;
    },
    SET_STANDARDDETAILS(state, payload) {
      state.standardDetails = payload;
    },
  },
  actions: {
    async getStandards({commit, state}, forcePull) {
      commit('SET_LOADING', true);
      if ((state.standards && state.standards.length === 0) ||
        forcePull) {
        const standards = await hierarchyService.getStandards();
        commit('SET_STANDARDS', standards.items);
      }
      commit('SET_LOADING', false);
    },
    async getStandardDetails({commit, state}, forcePull) {
      commit('SET_LOADING', true);
      if ((state.standardDetails && state.standardDetails.length === 0) ||
        forcePull) {
        const standardDetails = await hierarchyService.getStandardDetails();
        commit('SET_STANDARDDETAILS', standardDetails.items);
      }
      commit('SET_LOADING', false);
    }
  }
};