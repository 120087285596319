import Vue from 'vue'
import VueRouter from 'vue-router'

import PublishSelection from '../views/PublishSelection';
import AuthorMetaData from '../views/AuthorMetaData';

Vue.use(VueRouter)

const routes = [
  {
    path: '/publish-selection/:curriculumGuid?',
    name: 'PublishSelection',
    component: PublishSelection
  },
  {
    path: '/author-meta-data/:curriculumGuid?',
    name: 'AuthorMetaData',
    component: AuthorMetaData
  }
]

const router = new VueRouter({
  routes
})

export default router
