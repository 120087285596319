export default {
  props: {
    startCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      collapsed: false,
    }
  },
  mounted () {
    this.collapsed = this.$props.startCollapsed;
  },
  computed: {
    toggleIcon() {
      return this.collapsed ? 'mdi-plus' : 'mdi-minus';
    },
    isCollapsed() {
      return this.collapsed;
    }
  },
  methods: {
    toggleView() {
      this.collapsed = !this.collapsed;
    }
  },
  watch: {
  },
}