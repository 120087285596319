<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="1" offset="10">
          <v-btn @click.stop="showPublishDialog">
            Publish
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="tree-view">
          <v-row>
            <v-col>
              Curriculum File Type Summary
              <file-type-grid
                :curriculum-guid="curriculumGuid">
              </file-type-grid>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <publish-overrides
                :curriculum-guid="curriculumGuid"
                :webcon-user="webConUser">
              </publish-overrides>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4" class="tree-view">
          Curriculum Structure
          <tree-view
            :show-search="false"
            :show-checkbox="true"
            :curriculum-guid="curriculumGuid"
            :webcon-user="webConUser"
            :showUnpublishableIcon="true"
            @selected="selectNode"
            @checked="checkNode">
          </tree-view>
        </v-col>
        <v-col cols="4" class="tree-view">
          <file-type-detail
            v-if="selectedNode"
            :curriculum-guid="curriculumGuid"
            :selectedNode="selectedNode"
            :webcon-user="webConUser">
          </file-type-detail>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="showDialog"
      persistent
      width="60%">
      <v-card rounded shaped>
        <v-card-title class="chub-green lighten-2">Publish Curriculum</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-form ref="publishForm">
                  <v-text-field
                    label="Publish Title"
                    v-model="publishOptions.title"
                    :rules="[publishOptions.rules.required]"
                    @focus="setFocusHelp('publishTitle')"
                    @blur="hideHelp()">
                  </v-text-field>
                  <v-text-field
                    label="Description"
                    v-model="publishOptions.description"
                    :rules="[publishOptions.rules.required]"
                    @focus="setFocusHelp('publishDesc')"
                    @blur="hideHelp()">
                  </v-text-field>
                  <v-select
                    label="Path Types"
                    :rules="[publishOptions.rules.required]"
                    v-model="publishOptions.pathType"
                    :items="pathTypes"
                    @focus="setFocusHelp('publishPathType')"
                    @blur="hideHelp()">
                  </v-select>
                  <v-select
                    label="Output Type"
                    v-model="publishOptions.outputType"
                    :items="publishPackageTypes"
                    :rules="[publishOptions.rules.required]"
                    @focus="setFocusHelp('publishOutputType')"
                    @blur="hideHelp()">
                  </v-select>
                  <v-select
                    label="Publish Language"
                    v-model="publishOptions.language"
                    :items="publishLanguage"
                    :rules="[publishOptions.rules.required]"
                    @focus="setFocusHelp('publishLanguage')"
                    @blur="hideHelp()">
                  </v-select>
                  <v-select
                    label="Standards Set"
                    v-model="publishOptions.selectedStandard"
                    :items="publishStandards"
                    :rules="[publishOptions.rules.required]"
                    @focus="setFocusHelp('publishStandards')"
                    @blur="hideHelp()"
                    item-text="displayName"
                    return-object>
                  </v-select>
                  <v-select
                    label="Lower Grade Range"
                    v-model="publishOptions.lowerGradeRange"
                    :items="publishOptions.gradeRangeList"
                    :rules="[publishOptions.rules.required]"
                    @focus="setFocusHelp('gradeRange')"
                    @change="gradeRangeChange('lower', $event)"
                    @blur="hideHelp()"
                    return-object>
                  </v-select>
                  <v-select
                    label="Upper Grade Range"
                    v-model="publishOptions.upperGradeRange"
                    :items="publishOptions.gradeRangeList"
                    :rules="[publishOptions.rules.required]"
                    @focus="setFocusHelp('gradeRange')"
                    @change="gradeRangeChange('upper', $event)"
                    @blur="hideHelp()"
                    return-object>
                  </v-select>
                </v-form>
              </v-col>
              <v-col>
                <v-card v-if="showHelp">
                  <v-card-title class="grey lighten-2"><v-icon>mdi-information-outline</v-icon> {{focusedField}}</v-card-title>
                  <v-card-text>
                    {{focusedHelpText}}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="doPublish">
            Publish
          </v-btn>
          <v-btn text color="red lighten-2" @click="showDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FileTypeGrid from '../components/FileTypeGrid';
import FileTypeDetail from '../components/FileTypeDetail';
import TreeView from '../components/TreeView';
import PublishOverrides from '../components/PublishOverrides';

import FileTypeMixin from '../mixins/fileTypeParser.mixin';

import publishSvc from '../services/publish.service';
import hierarchySvc from '../services/hierarchy.service';

export default {
  mixins: [ FileTypeMixin ],
  components: {
    FileTypeGrid,
    FileTypeDetail,
    TreeView,
    PublishOverrides,
  },
  data: () => {
    return {
      selectedNode: null,
      checkedNodes: [],
      showDialog: false,
      showHelp: false,
      focusedField: '',
      focusedHelpText: '',
      webConUser: '',
      publishOptions: {
        title: '',
        description: '',
        outputType: '',
        pathType: '',
        languge: '',
        selectedStandard: '',
        instanceId: -1,
        lowerGradeRange: '',
        upperGradeRange: '',
        gradeRangeList: [],
        rules: {
          required: value => !!value || 'Required.',
        }
      },
      publishPackageTypes: [
        { text: 'Common Cartridge 1.3 Thick', value: 'CommonCartridge13' },
        { text: 'Common Cartridge 1.3 Thin', value: 'CommonCartridge13Thin' },
        { text: 'Google Drive Delivery', value: 'GoogleDriveDelivery' },
        { text: 'Package (Zipped Folder Structure)', value: 'Package' },
        { text: 'Canvas Common Cartridge', value: 'Canvas' },
        { text: 'Safari Common Cartridge', value: 'Safari' },
      ],
      publishLanguage: [
        { text: 'English', value: 'en' },
        { text: 'Spanish', value: 'es' }
      ],
      pathTypes: [
        { text: 'Full Path', value: 'FullPath' },
        { text: 'Common Ancestor', value: 'CommonAncestor' },
      ],
      publishStandards: [],
    }
  },
  computed: {
    curriculumGuid() {
      return this.$route.params.curriculumGuid;
    }
  },
  methods: {
    receiveDataFromParent(event) {
      if (event.origin !== process.env.VUE_APP_PARENT_IFRAME) {
        // console.log(`Expected Origin mismatch. Received ${event.origin}`);
        return;
      }
      console.debug('Recieved Message from Parent - Event: %O', event);

      const { data } = event;
      const { configuration } = data;
      const { sdkConfiguration } = configuration;

      if (data.message !== 'SET_CONFIGURATION') {
        console.error(`Expected Message mismatch. Received ${event.message}`);
        return;
      }
      if (!sdkConfiguration) {
        console.error('Received invalid SdkCnfiguratin Object. Check Webcon configurations');
        return;
      }

      if (sdkConfiguration.webconUser) {
        this.webConUser = sdkConfiguration.webconUser
      } else {
        console.error('Missing WebCon User form sdkConfiguration Object.  Chekc Webcon configurations');
        return;
      }

      this.publishOptions.title = sdkConfiguration.publishTitle;
      this.publishOptions.description = sdkConfiguration.publishDescription;
      this.publishOptions.outputType = sdkConfiguration.outputType;
      this.publishOptions.language = sdkConfiguration.publishLanguage;
      this.publishOptions.instanceId = sdkConfiguration.InstanceId;
      this.publishOptions.lowerGradeRange = sdkConfiguration.DefaultGradeRange.split('-')[0];
      this.publishOptions.upperGradeRange = sdkConfiguration.DefaultGradeRange.split('-')[1]
      this.publishOptions.gradeRangeList = sdkConfiguration.GradeList;
    },
    selectNode(item) {
      console.log('PublishSelection:selectNode - Clearing Item');
      this.selectedNode = null;
      if (!item || !item.type) {
        console.log('PublishSelection:selectNode - Item Deselected');
        this.selectedNode = null;
        return;
      }
      console.log('PublishSelection:selectNode - %e', item);
      this.selectedNode = item;
    },
    checkNode(items) {
      this.checkedNodes = items;
    },
    hideHelp() {
      this.showHelp = false;
    },
    async showPublishDialog() {
      this.showDialog = true;
      let result = await hierarchySvc.getCurriculumStandardSets(this.curriculumGuid);
      this.publishStandards = result.items;
      this.publishStandards.forEach(standard => {
        standard.displayName = standard.name.match(/[A-Z:(0-9)]/g).join('');
      });
      this.publishStandards.unshift({
        id: -1,
        displayName: 'Do Not Include Standards',
        docIdentifier: '',
      })
    },
    doPublish() {
      if (!this.$refs.publishForm.validate()) {
        return;
      }
      publishSvc.publishCurriculum(
        this.curriculumGuid,
        this.publishOptions,
        this.checkedNodes,
        this.$store.getters.CurriculumSummary,
        this.webConUser);

      this.showDialog = false;
    },
    setFocusHelp(field) {
      this.showHelp = true;
      switch(field) {
        case 'publishTitle':
          this.focusedField = 'Publish Title';
          this.focusedHelpText = 'Provides a title for this insance of the pubished curriculum';
          break;
        case 'publishDesc':
          this.focusedField = 'Publish Description';
          this.focusedHelpText = 'Provides a description for this insance of the pubished curriculum';
          break;
        case 'publishPathType':
          this.focusedField = 'Path Types';
          this.focusedHelpText = 'Determines if the published curriculum will include all folders up to the root folder, or stop at the first common ancestor.  (ex. If a file in a sub folder is selected, and a file in a sibling sub folder is selected, the publish will only publish to the parent folder.)';
          break;
        case 'publishOutputType':
          this.focusedField = 'Publish Output Type';
          this.focusedHelpText = 'Determines how the published curriculum will be delivered.';
          break;
        case 'publishLanguage':
          this.focusedField = 'Publish Language';
          this.focusedHelpText = 'Selects the language in which to use when publishing the curriculum.';
          break;
        case 'publishStandards':
          this.focusedField = 'Published Standards';
          this.focusedHelpText = 'Indicates which set of standrds will be published in the resulting cartridge.';
          break;
        case 'gradeRange':
          this.focusedField = 'Grade Range';
          this.focusedHelpText = 'Indicates the grade ranges that will be published in the resulting cartridge.';
          break;
      }
    },
    gradeRangeChange(ctrl) {
      const lowerGradeVal = this.publishOptions.lowerGradeRange === 'PK' ? -2 : this.publishOptions.lowerGradeRange === 'K' ? -1 : Number(this.publishOptions.lowerGradeRange);
      const upperGradeVal = this.publishOptions.upperGradeRange === 'PK' ? -2 : this.publishOptions.upperGradeRange === 'K' ? -1 : Number(this.publishOptions.upperGradeRange);
      console.log(`${lowerGradeVal}-${upperGradeVal}`);
      switch (ctrl) {
        case 'lower':
          console.log('Processing Lower');
          if (lowerGradeVal > upperGradeVal) {
            console.log('Resetting Upper Grade Range')
            this.publishOptions.upperGradeRange = this.publishOptions.lowerGradeRange;
          }
          break;
        case 'upper':
          console.log('Processing Upper');
          if (upperGradeVal < lowerGradeVal) {
            console.log('Resetting Lower Grade Range')
            this.publishOptions.lowerGradeRange = this.publishOptions.upperGradeRange;
          }
          break;
      }
    }
  },
  mounted() {
    window.addEventListener('message', this.receiveDataFromParent, false);

    window.parent.postMessage({ message: 'IFRAME_LOADED' }, '*');

    if (process.env.VUE_APP_MODE === 'Development'){
      this.webConUser = 'rrastelli@gedu-demo-pcgus.com';
      this.publishOptions.gradeRangeList = ['PK','K','1','2','3','4','5','6','7','8','9','10','11','12'];
      this.publishOptions.lowerGradeRange = 'K';
      this.publishOptions.upperGradeRange = '8';
    }
  },
  destroyed() {
    window.removeEventListener('message', this.receiveDataFromParent);
  }
}
</script>

<style scoped>
.col.tree-view {
  height: 90vh;
  min-height: 90vh;
  overflow-y: scroll;
}
.chub-green {
  background-color: rgb(111, 181, 79);
  color: white;
}
</style>