<template>
  <div>
    <v-card class="tree-container" elevation="0">
      <v-text-field placeholder="Search Hierarchy"
        v-if="showSearch"
        @keydown="reOpenNodes"
        @focus="setFocus"
        v-model="searchString">
        <v-icon slot="append">mdi-folder-search-outline</v-icon>
      </v-text-field>
      <v-treeview
        :items.sync="items"
        :open.sync="openNodes"
        item-key="uniqueId"
        open-all
        return-object
        dense
        activatable
        transition
        selection-type="leaf"
        :selectable="showCheckbox"
        v-model="selectedNodes"
        @update:active="nodeSelected"
        @input="nodeChecked">
          <template v-slot:prepend="{item}">
            <div v-if="item.type.toUpperCase() === 'FOLDER'" class="treeIcon rootFolder"></div>
            <div v-else class="treeIcon" :class="getIcon(item)"></div>
          </template>
          <template v-slot:label="{item}">
            <span :class="backgroundColor(item.name)">{{ item.name }}</span>
          </template>
          <!-- <template v-slot:label="{item}">
            <div v-if="item.type === 'FOLDER'" class="treeIcon rootFolder"></div>
            <div v-else class="treeIcon" :class="parseFileType(item.type)"></div>
            {{ item.name }}
            <div v-if="item.type === 'FOLDER'" class=""></div>
            <div v-else class="treeIcon" :class="parseFileType(item.publishType)"></div>
          </template> -->
      </v-treeview>
      <v-overlay
        :value="loading"
        absolute>
        <v-progress-circular
          indeterminate
          size="64">
        </v-progress-circular>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
import fileTypeParser from '../mixins/fileTypeParser.mixin.js';

export default {
  mixins: [fileTypeParser],
  emits: ['selected', 'checked'],
  props: {
    showCheckbox: {
      type: Boolean,
      default: true,
    },
    curriculumGuid: {
      type: String,
    },
    webconUser: {
      type: String,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    showUnpublishableIcon: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      openNodes: [],
      selectedNodes: [],
      searchString: '',
      openAllNodes: false,
      hasFocus: false,
    };
  },
  watch: {
    curriculumGuid(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch('getCurriculumHierarchy',
          this.$props.curriculumGuid,
          this.$props.webconUser);
      }
    },
    items(newValue) {
      if (!newValue) {
        return;
      }
      this.openNodes = this.getFolderNodes(newValue).flat();
    },
  },
  mounted() {
    this.$store.dispatch('getCurriculumHierarchy',
      this.$props.curriculumGuid,
      this.$props.webconUser);
  },
  computed: {
    items() {
      if (!this.$store.getters.Hierarchy) {
        return [];
      }
      return this.$store.getters.Hierarchy;
    },
    loading() {
      return this.$store.getters.Loading;
    },
  },
  methods: {
    async fetchData() {
      this.openNodes = this.getFolderNodes(this.items).flat();
    },
    getFolderNodes(items) {
      const folders = [];
      items.forEach(obj => {
        if (obj.type.toUpperCase() === 'FOLDER') {
          folders.push(obj);
          if (obj.children && obj.children.length > 0) {
            folders.push(this.getFolderNodes(obj.children));
          }
        }
      });
      return folders.flat();
    },
    nodeSelected(item) {
      console.log('TreeView:nodeSelected - %e', item);
      if (item.length === 1) {
        this.$emit('selected', item[0]);
      } else {
        this.$emit('selected', {});
      }
    },
    nodeChecked(selectedItems) {
      // selectedItems.forEach((node) => {
      //   this.selectedNodes.push(this.flattenNode(node));
      // });
      this.$emit('checked', selectedItems);
      //this.selectedNodes = selectedItems.filter((item) => { return item.type.toUpperCase() !== 'FOLDER' });
    },
    flattenNode(node) {
      if (node.type.toUpperCase() !== 'FOLDER') {
        return node;
      }
      const flattenChildren = [];
      node.children.forEach((child) => {
        flattenChildren.push(this.flattenNode(child));
      });
      return flattenChildren;
    },
    backgroundColor(itemName) {
      if (this.searchString.length === 0) {
        return '';
      }
      if (itemName.search(this.searchString) >= 0) {
        return 'search-target';
      }
    },
    expandAllNodes() {
      this.openNodes = this.getFolderNodes(this.items).flat();
    },
    reOpenNodes() {
      if (this.hasFocus) {
        this.expandAllNodes();
        this.hasFocus = false;
      }
    },
    setFocus() {
      if (!this.hasFocus) {
        this.hasFocus = true;
      }
    },
    getIcon(item) {
      if (this.$props.showUnpublishableIcon) {
        if (!item.publishable) {
          return 'unpublishable';
        }
      }
      return this.parseFileType(item.type);
    }
  },
}
</script>

<style>
.v-treeview--dense .v-treeview-node__root {
    min-height: 30px;
    cursor: pointer;
}
</style>

<style scoped>
.tree-container {
  min-height: 90vh;
}
.search-target {
  background-color: yellow;
}
</style>