import Vue from 'vue'
import Vuex from 'vuex'

import hierarchyService from '../services/hierarchy.service';
import authorService from '../services/author.service';

Vue.use(Vuex)
import standards from './standards';

// function findNode(nodes, hierarchyGuid) {
//   var foundNode = nodes.filter((node) => {
//     return node.guid === hierarchyGuid && node.type.toLowerCase() === 'folder';
//   });
//   if (foundNode && foundNode.length === 1) {
//     return foundNode[0];
//   }
//   foundNode = null;
//   nodes.filter((node) => {
//     return node.type.toLowerCase() === 'folder';
//   }).every((node) => {
//     foundNode = findNode(node.children, hierarchyGuid);
//     return foundNode === null;
//   });
//   return foundNode;
// }
function setUniqueIds(hierarchy) {
  hierarchy.uniqueId = hierarchy.type.toLowerCase() === 'folder' ? `F${hierarchy.id}` : `O${hierarchy.id}`;
  if (hierarchy.children.length === 0) {
    return;
  }
  hierarchy.children.forEach(child => {
    setUniqueIds(child);
  })
}
export default new Vuex.Store({
  modules: {
    standards
  },
  state: {
    curriculumSummary: [],
    publishTypeMap: [],
    hierarchy: [],
    curriculumGuid: '',
    loading: true,
  },
  getters: {
    CurriculumSummary: (state) => state.curriculumSummary,
    PublishTypeMap: (state) => state.publishTypeMap,
    Hierarchy: (state) => state.hierarchy,
    Loading: (state) => state.loading,
    CurriculumGuid: (state) => state.curriculumGuid,
  },
  mutations: {
    SET_CURRICULUM_SUMMARY(state, payload) {
      state.curriculumSummary = payload;
    },
    SET_PUBLISH_TYPE_MAP(state, payload) {
      state.publishTypeMap = payload;
    },
    SET_CURRICULUM_HIERARCHY(state, payload) {
      state.hierarchy = payload;
    },
    SET_CURRICULUM_GUID(state, payload) {
      state.curriculumGuid = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    UPDATE_HIERARCHY(state, payload) {
      state.hierarchy = payload;
    },
    UPDATE_CURRICULUM_SUMMARY(state, payload) {
      let summaryNode;
      for (let index = 0; index < state.curriculumSummary.length; index++) {
        const summary = state.curriculumSummary[index];
        summaryNode = payload.filter(node => {
          return node.objectType === summary.objectType;
        })[0];
        if (summaryNode) {
          summary.filesCount = summaryNode.filesCount;
        } else {
          state.curriculumSummary.splice(index, 1);
        }
      }
    }
  },
  actions: {
    async getCurriculumSummary({commit, state}, curriculumGuid) {
      commit('SET_LOADING', true);
      if ((state.curriculumSummary && state.curriculumSummary.length === 0) || 
        (state.curriculumGuid !== curriculumGuid)){
        const summary = await hierarchyService.getCurriculumObjectSummary(curriculumGuid);
        commit('SET_CURRICULUM_GUID', curriculumGuid);
        commit('SET_CURRICULUM_SUMMARY', summary);
      }
      commit('SET_LOADING', false);
    },
    async updateCurriculumSummary({commit, state}) {
      commit('SET_LOADING', true);
      const summary = await hierarchyService.getCurriculumObjectSummary(state.curriculumGuid);

      commit('UPDATE_CURRICULUM_SUMMARY', summary);

      commit('SET_LOADING', false);
    },
    async getPublishTypeMap({commit, state}) {
      commit('SET_LOADING', true);
      if (state.publishTypeMap && state.publishTypeMap.length === 0) {
        const map = await hierarchyService.getPublishTypeMap();
        commit('SET_PUBLISH_TYPE_MAP', map);
      }
      commit('SET_LOADING', false);
    },
    async getCurriculumHierarchy({commit, state}, curriculumGuid, webconUser) {
      commit('SET_LOADING', true);
      if ((!state.hierarchy) ||
        (state.hierarchy && state.hierarchy.length === 0) || 
        (state.curriculumGuid !== curriculumGuid)){
        const hierarchy = await hierarchyService.getCurriculumHierarchy(curriculumGuid, webconUser);
        setUniqueIds(hierarchy);
        commit('SET_CURRICULUM_GUID', curriculumGuid);
        commit('SET_CURRICULUM_HIERARCHY', [hierarchy]);
      }
      commit('SET_LOADING', false);
    },
    reloadHierarchy({commit}) {
      commit('SET_CURRICULUM_HIERARCHY', null);
    },
    async createPlaylist({commit, state}, payload) {
      await authorService.createPlaylist({
        name: payload.playlistName,
        curriculumGuid: state.curriculumGuid,
        hierarchyGuid: payload.hierarchyGuid,
        language: payload.language,
      },
      payload.webconUser);

      commit('SET_CURRICULUM_HIERARCHY', null);
    }
  },
})
