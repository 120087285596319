<template>
  <div>
    <v-simple-table
      dense 
      fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Source Type
            </th>
            <th class="text-left">
              Count
            </th>
            <th class="text-left">
              Publish File Type
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="file in curriculumFileSummary"
            :key="file.objectType">
            <td><span class="treeIcon" :class="parseFileType(file.objectType)"></span>{{ file.objectType }}</td>
            <td>{{ file.filesCount }}</td>
            <td class="text-left">
              <v-select :items="publishTypes(file.objectType)"
                v-model="file.publishType"
                item-text="name"
                item-value="publishType">
                <template v-slot:item="{ item }">
                  <div class="treeIcon" :class="parseFileType(item.publishType)"></div>
                  <span class="v-select-item-text">{{item.name}}</span>
                </template>
                <template v-slot:selection="{ item }">
                  <div class="treeIcon" :class="parseFileType(item.publishType)"></div>
                  <span class="v-select-item-text">{{item.name}}</span>
                </template>
              </v-select>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64">
      </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import fileTypeParser from '../mixins/fileTypeParser.mixin';

export default {
  mixins: [fileTypeParser],
  props: {
    curriculumGuid: {
      type: String,
    },
  },
  data: () => {
    return {
      loading: false,
    }
  },
  computed: {
    curriculumFileSummary() {
      return this.$store.getters.CurriculumSummary;
    }
  },
  mounted() {
    // this.$store.dispatch('getCurriculumSummary', this.$props.curriculumGuid);
  },
  methods: {
  },
  watch: {
    curriculumGuid() {
      this.$store.dispatch('getCurriculumSummary', this.$props.curriculumGuid);
    }
  },
}
</script>

<style scoped>
.v-select-item-text {
  padding-left: 3px;
}
.treeIcon {
  margin-right: 3px;
}
</style>