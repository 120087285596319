import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import vueCookies from 'vue-cookies';
import CKeditor from '@ckeditor/ckeditor5-vue2';

Vue.use(vueCookies, {
  expires: '7d',
  path: '/',
  domain: `${process.env.VUE_APP_PARENT_IFRAME}`,
});

Vue.use(CKeditor);

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
